import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import "../index.scss";
import * as styles from "./default.module.scss";

type DefaultLayoutProps = {
    children: React.ReactNode;
    navTransparent?: boolean;
}

const DefaultLayout = (props: DefaultLayoutProps) => {
    const [isAtTop, setIsAtTop] = useState(false);

    const updateIsAtTop = () => {
        if(typeof window === "undefined") return;

        console.log(window.scrollY);

        if(window.scrollY > 25) {
            if(isAtTop) setIsAtTop(false);
        } else {
            if(!isAtTop) setIsAtTop(true);
        }
    }

    useEffect(() => {
        if(typeof window !== "undefined") {
            window.addEventListener("scroll", updateIsAtTop);
            updateIsAtTop();
        }

        return () => {
            if(typeof window !== "undefined") {
                window.removeEventListener("scroll", updateIsAtTop);
            }
        }
    })

    return <div className={styles.layout}>
        <div className={styles.topbar + (props.navTransparent && isAtTop ? " "+styles.transparent : "")}>
            <nav>
                <div className={styles.logo}><Link to={"/"}>K</Link></div>
                {/*<div className={styles.mainNav}>
                    <Link to={"/about"}>About</Link>
                    <Link to={"/manifesto"}>Manifesto</Link>
                </div>
                <div className={styles.subNav}>
                    <Link to={"/login"} className={styles.loginBtn}>
                        <span>
                            <span>Login</span>
                        </span>
                    </Link>
                </div>*/}
            </nav>
        </div>
        <main>{props.children}</main>
        <footer>CC-BY-4.0 Kevin Kandlbinder | <a href={"https://kevink.dev/legal/imprint"}>Imprint</a></footer>
    </div>
}

export default DefaultLayout;