import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import DefaultLayout from "../layout/default"
import {Helmet} from "react-helmet";

import * as styles from "./index.module.scss";

const IndexPage = () => {
  return (
    <DefaultLayout navTransparent={true}>
      <Helmet>
        <title>K-Re:volt</title>
      </Helmet>
      <div className={styles.heroSection}>
        <div className={styles.heroBg}>
          <StaticImage src={"../images/yves-alarie-z79gh4qK8kQ-unsplash.jpg"} alt="Hero Image" className={styles.heroImg} objectFit={"cover"}></StaticImage>
          <div className={styles.splash}></div>
        </div>
        <div className={styles.heroText}>
          <span>Join the <br/><span className={styles.accent}>Re:</span><wbr/>volt</span>
        </div>
        <span className={styles.comingSoon}>Coming Soon(ish)™</span>
        <span className={styles.heroCredit}>Photo by <a href="https://unsplash.com/@yvesalarie?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Yves Alarie</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></span>
      </div>
      {/*<div>
        <div className={styles.layoutBox}>
          <h1>About the Re:volt</h1>
        </div>
      </div>*/}
    </DefaultLayout>
  )
}

export default IndexPage
